.blog-title {
  font-weight: 900;
  margin: 30px 40px 0px 40px;
}

.blog-page-desc {
  margin: 20px 40px 20px 40px;
}

.blog {
  margin: 30px 40px 0px 40px;
}

.blog-image {
  background-position: center;
  background-size: cover;
  color: white;
  height: 200px;
}

.post-page {
  margin: 40px 0px;
}

.post-page h1 {
  font-size: 30px;
}

.meta-data {
  font-size: 20px;
  color: #255D90;
}

sup {
  font-weight: 900;
}

.post-image {
  width: 400px;
  height: auto;
  float: right;
}

.post-page h4 {
  margin-top: 20px;
}

.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin: 20px 0;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}


/* MOBILE STYLING */

@media only screen and (max-width: 768px) {
  .blog-title {
    margin: 30px 0px 20px 0px;
  }

  .blog-page-desc {
    margin: 0px 0px 10px 0px;
  }

  .blog {
    margin: 30px 0px 0px 0px;
  }

  .blog-image {
    height: 150px;
  }
}