/* STYLING FOR EVERYTHING */
* {
    font-family: 'Poppins', sans-serif !important;
  }
  
  body {
    overflow-x: hidden;
  }
  
  hr {
    border: 1px solid #255D90;
    border-radius: 25px;
    width: 50%;
    margin: 12px auto;
    opacity: 1;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover {
    text-decoration: none;
    color: #255D90;
  }
  
  button {
    border-radius: 4px;
    border: none;
    background-color: #255D90;
    color: #ffffff;
  }
  
  button:hover {
    opacity: 0.7;
  }
  
  textarea, select, input:not(input[type='submit'], input[type='reset']) {
    border: none;
    border-radius: 4px;
    background-color: #eeeeee;
    padding: 8px;
  }
  
  textarea {
    resize: none;
  }
  
  /* NAVBAR STYLING */
  header {
    width: 100%;
    height: 120px;
    background-color: #255D90;
  }
  
  header a {
    position: relative;
    height: 100px;
    background-color: inherit;
    border: none;
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;
    margin-left: 25px;
  }
  
  header a:hover {
    color: #ffffff;
    opacity: 0.7;
  }
  
  header img:hover {
    opacity: 0.7;
  }
  
  .nav-buttons {
    position: relative;
    top: 30px;
    right: 30px;
    display: flex;
    justify-content: right;
  }
  
  .nav-mobile {
    display: none;
  }
  
  /* FOOTER STYLING */
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;/*#71A4D4;*/
    height: 230px;
    width: 100vw;
    padding-left: 5%;
  }
  
  footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-mobile {
    display: none;
  }
  
  /* GLOBAL CSS CLASSES */
  .main {
    min-height: 70vh;
    width: 100vw;
  }
  
  .center {
    text-align: center;
  }
  
  .flex-center {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  
  .linkedin {
    background-image: url('images/icons/linkedin.png');
  }
  
  .github {
    background-image: url('images/icons/github.png');
  }
  
  /* ANIMATIONS */
  
  /* Fade up animation */
  @keyframes fadeInUp {
    from {
      transform: translate3d(0,40px,0)
    }
  
    to {
      transform: translate3d(0,0,0);
      opacity: 1
    }
  }
  
  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0,40px,0)
    }
  
    to {
      transform: translate3d(0,0,0);
      opacity: 1
    }
  }
  
  .animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: both
  }
  
  .animatedFadeInUp {
    opacity: 0
  }
  
  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
  
  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1140px !important;
    }
  }
  
  /* MOBILE STYLING */
  
  @media only screen and (max-width: 768px) {
    .nav-desktop {
      display: none;
    }
  
    .nav-mobile {
      display: inline-flex;
    }
  
    .nav-mobile #dropdown-basic-button {
      margin-top: 15px;
      color: #00274C;
      background-color: #FFCB05;
      border-color: #FFCB05;
    }
  
    .nav-mobile a {
      height: inherit;
      font-size: inherit;
      margin-left: 0px;
    }
  
    .footer-desktop {
      display: none;
    }
  
    .footer-mobile {
      display: inline-flex;
    }
  
    .footer-mobile h5 {
      color: #255D90;
    }
  
    .footer-mobile img {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  