.projects-header {
  margin: 30px 0;
}

.projects-card {
  text-align: center;
  background-position: center;
  background-size: cover;
  color: white;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 18px 18px 45px 0 rgb(0 0 0 / 10%);
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  transform: scale3d(1none, 1none, 1none);
  transition: transform 225ms ease;
  cursor: pointer;
  transform-style: preserve-3d;
}

.projects-card:hover {
  text-decoration: none;
  transform: scale(1.05);
}

.projects-accordion {
    margin-bottom: 40px;
}

.projects-accordion-card {
    margin-top: 20px;
    background-color: #e4e4e4;
}

.projects-accordion-toggle {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: x-large;
}

.project-page {
  margin: 30px 0px;
}

.project-page h5 {
  margin-bottom: 20px;
  font-size: small;
}

.project-page img {
  max-width: 50vw;
}

.project-page img:hover {
  transform: scale(1.5);
}

.project-page figure {
  max-width: 50vw;
}

.project-page figcaption {
  color: #255D90;
  font-size: 0.9em;
}

.aside-right {
  float: right;
  border-left: 3px solid #255D90;
  padding-left: 10px;
  margin-left: 5px;
}

.aside-left {
  float: left;
  border-right: 3px solid #255D90;
  padding-right: 10px;
  margin-right: 10px;
}

/* MOBILE STYLING */

@media only screen and (max-width: 768px) {
  .project-page img {
    max-width: 90vw;
  }

  .project-page figure {
    max-width: 100vw;
  }

  .project-page img:hover {
    transform: scale(1);
  }

  .aside-right, .aside-left {
    float: none;
    border: none;
    padding: 0px;
    margin: 0px;
  }

}

