.home-banner {
  background-color: #255D90;
  height: 490px;
}

.home-banner-text {
  padding-top: 75px;
  color: #ffffff;
}

.home-banner-text h1 {
  width: 100%;
  font-weight: 900;
  font-size: 36px;
}

.home-banner-text h3 {
  font-weight: 900;
  font-size: 24px;
  line-height: 50px;
  color: #d9d9d9;
}

.home-banner-text a {
  color: #FFCB05;
}

.home-banner-text a:hover {
  color: #d9d9d9;
}

.home-projects-container {
  font-weight: 900;
  margin: 30px 40px 20px 40px;
}

.home-projects-container-link {
    font-weight: 600;
    margin: 0px 40px 20px 40px;
    font-size: 22px;
}

.home-project {
  margin: 30px 40px 0px 40px;
}

.home-project-image {
  background-position: center;
  background-size: cover;
  color: white;
  height: 200px;
}

.home-eboard {
  background-color: #D3E3F2;
  padding-bottom: 20px;
}

.home-eboard-container {
  padding-top: 30px;
}

.home-eboard-bio {
  padding: 10px 40px 0px 40px;
}

.bio img {
  width: 225px;
  height: 225px;
  border-radius: 175px;
  border: 8px solid #255D90;
  object-fit: cover;
  object-position: center;
  margin-bottom: 15px;
}

.bio h4 {
  font-weight: 900;
}

.bio h5 {
  color: #255D90;
}

.bio-lead p {
  font-size: 8;
  margin: none;
  padding: none;
}

.bio-lead img {
  width: 150px;
  height: 150px;
  border-radius: 175px;
  border: 6px solid #255D90;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;
}

.bio-lead h4 {
  font-weight: 900;
}

.bio-lead h6 {
  color: #255D90;
}

.home-contact {
  margin: 30px 40px 0px 40px;
}

.home-contact h1 {
  font-weight: 900;
}

.home-contact img {
  margin: 100px 0px 0px 55px;
}

.home-contact label {
  font-size: 22px;
  margin-top: 5px;
  color: #255D90;
}

.home-contact input {
  width: 100%;
}

.home-contact textarea {
  height: 100px;
  width: 100%;
}

.home-contact button {
  width: 100%;
  height: 50px;
  font-size: 24px;
  font-weight: 900;
  margin: 20px 0px 30px 0px;
}

.bracket-background {
  background-color: #ffffff;
  height: 'auto';
}

.bracket-selector {
  border-radius: 10px;
  background-color: #d9d9d9;
  padding: 20px;
  height: 'auto';
  margin-bottom: 15px;
}

.error {
  color: red;
}

.success {
  color: green;
  font-size: 24px;
  line-height: 26px;
}

/* MOBILE STYLING */

@media only screen and (max-width: 768px) {
  .home-page {
    text-align: center;
  }

  .home-page form {
    text-align: left;
  }

  .home-banner {
    height: 420px;
  }

  .home-banner img {
    display: none;
  }

  .home-banner-text {
    padding: 0px 10px 0px 10px;
  }

  .home-projects-container {
    margin: 30px 0px 20px 0px;
  }

  .home-projects-container-link {
    margin: 30px 0px 20px 0px;
  }

  .home-project {
    margin: 30px 0px 0px 0px;
  }

  .home-project-image {
    height: 150px;
  }

  .home-eboard-bio {
    padding: 10px 0px 0px 0px;
  }

  .home-contact {
    margin: 30px 0px 0px 0px;
  }

  .home-contact img { 
    margin: 10px 0px 20px 0px;
    width: 50vw;
    height: auto;
  }
}